import { ThemeProvider } from '@emotion/react';

import * as React from 'react';

import { compose } from 'recompose';

import Retracked from 'js/app/retracked';

import Alice from 'bundles/alice/components/Alice';
import { PROGRAM_HOME } from 'bundles/alice/constants/AliceContextType';
import withAliceNotification from 'bundles/alice/lib/withAliceNotification';
import AlicePageviewEvent from 'bundles/alice/models/AlicePageviewEvent';
import { searchTheme } from 'bundles/search-common/searchTheme';
import UnifiedAppCheckProvider from 'bundles/unified-common/providers/UnifiedAppCheckProvider';
import withMarketingConsent from 'bundles/user-consent/components/withMarketingConsent';

import 'css!bundles/unified-home-common/index';

// Note this component is attached to the route component. We don't have access to page data providers here.
const EnterprisePageWrapper: React.FC = ({ children }) => {
  return (
    <UnifiedAppCheckProvider>
      <ThemeProvider theme={searchTheme}>
        <Alice />
        {children}
      </ThemeProvider>
    </UnifiedAppCheckProvider>
  );
};

export default compose(
  withMarketingConsent,
  withAliceNotification(
    () => {
      return new AlicePageviewEvent({ contextType: PROGRAM_HOME });
    },
    // eslint-disable-next-line camelcase
    () => ({ course_id: 'NO_COURSE_ID' })
  ),
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'program_home_v3',
      page: 'home_page',
    },
  }))
)(EnterprisePageWrapper);
